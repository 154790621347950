import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';

const isLegacy = document.createElement('script').noModule === undefined;

if (isLegacy) {
  import(/* webpackChunkName: 'legacy-support' */ './polyfills.js').then(() => console.info('Legacy browsers support enabled'));
}

import('./main.js').then();

// THIS SCRIPT WILL LOAD ONLY WHEN THE EXPERIENCE EDITOR IS ENABLED
if (isExperienceEditorActive()) {
  import('./experience-editor-patches/index.js').then(() => console.info('EXPERIENCE EDITOR PATCHES LOADED'));
}
